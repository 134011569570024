import React from 'react'
import { Link } from 'gatsby'
import { urlConcat } from '../shared/utilities'

const PreviousNext = ({ baseUrl, type, previous, next }) => {
  const linkNext = next ? urlConcat(baseUrl, next.slug) : null
  const linkPrevious = previous ? urlConcat(baseUrl, previous.slug) : null

  return (
    <p className="row" style={{ paddingTop: '20px', fontSize: '16px' }}>
      {previous && (
        <span className="col-sm-6" style={{ margin: '0px' }}>
          &larr; <Link to={linkPrevious}>{previous.title}</Link>
        </span>
      )}
      {next && (
        <span className="col-sm-6 text-right" style={{ margin: '0px' }}>
          <Link to={linkNext}>{next.title}</Link>&rarr;
        </span>
      )}
    </p>
  )
}

export default PreviousNext
