import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import GoogleApiWrapper from '../components/GoogleMaps'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import richTextOptions from '../shared/richTextOptions'
import { getContentfulUrl } from '../shared/utilities'
import PreviousNext from '../components/PreviousNext'

const Accommodation = ({ pageContext, location }) => {
  const {
    title,
    description,
    region,
    longitude,
    latitude,
    profileImage,
    previous,
    next
  } = pageContext

  const imgQuery = '?w=700&h=300'
  const imgUrl = getContentfulUrl(profileImage) + imgQuery
  const coordinates = [parseFloat(latitude), parseFloat(longitude)]
  return (
    <Layout path={location.pathname}>
      <SEO title={title} />
      <div className="container accommodation text-content">
        <Link to="/resources/accommodation" className="back-link">
          &larr; Accommodation
        </Link>
        <h1>{title}</h1>
        <h3>{region}</h3>
        {profileImage && <img height="300px" src={imgUrl} alt={title} />}
        {description && documentToReactComponents(description.json, richTextOptions)}
        <div className="map-container">
          {coordinates && <GoogleApiWrapper coordinates={coordinates} />}
        </div>
        <PreviousNext
          type="Accomodation"
          baseUrl="/resources/accommodation"
          previous={previous}
          next={next}
        />
      </div>
    </Layout>
  )
}

export default Accommodation
